




import LabVaporPressureOfWater from '../simulations/LabVaporPressureOfWater.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';

export default defineComponent({
  name: 'VaporPressureOfWaterSIM',
  components: {LabVaporPressureOfWater, LabTask},
  mixins: [DynamicQuestionMixin()],
});
